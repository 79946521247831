* {
  padding: 0;
  margin: 0;
  outline: 0;
}
body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  top: 0;
  bottom: 0;
}
.Nav_brand {
  /* padding: 120px; */
  padding-left: 0px;
  position: relative;
  height: 80px;
}
.logo {
  position: relative;
  width: 140px;
  height: 170px;
  left: 0px;
  top: 25px;
}
.Nav {
  height: 100vh;
  border-radius: 0px 55px 51px 0px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  display: block;
  z-index: 1000;
  font-weight: 200;
  /* background: #01507a; */
  -webkit-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
  transition: all 0.3s;
}
.Nav_right .Nav__link:hover {
  /* display: block; */
  text-decoration: underline;
  /* margin: 6px; */
  border-radius: 50px;
  padding: 15px;
  color: #cdddde;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  color: #dde2ff;
}
.Nav_right .Nav__link {
  /* display: block; */
  text-decoration: none;
  /* margin: 6px; */
  padding: 20px;
  /* margin-top: 6%; */
  color: #cdddde;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #dde2ff;
}

.ullinks {
  list-style-type: none;
  padding: 10px;
  margin-left: 20px;
  margin-top: 40px;
  font-size: 14px;
}
.ullinks i {
  color: #fff;
  font-size: 16px;
  line-height: 60px;
}
.welcome {
  padding-top: 40px;
}
.rowunote {
  margin-left: auto;
  margin-right: auto;
}
.rowuno {
  margin-top: -20px;
  display: flex;
}
.induka {
  display: block;
  width: 18em;
  margin-left: 2em;
  margin-bottom: 25px;
  padding: 5px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgb(129, 143, 163);
  border-radius: 14px;
}

.logininputsselectyu {
  cursor: pointer;
  padding: 5px;
  color: #000000;
  font-size: 13px;
  font-weight: 800;
  background-color: transparent;
  background-image: none;
  border: none;
  border-radius: 14px;
}
.logininputsselect {
  display: block;
  width: 15em;
  height: 35px;
  margin-left: 2em;
  margin-bottom: 25px;
  font-size: 14px;
  color: #555;
  background-image: none;
  border: 1.1px solid rgb(129, 143, 163);
  border-radius: 14px;
}
.logininputsselectunity {
  cursor: pointer;
  font-size: 14px;
  color: #000000;
  font-size: 14px;
  font-weight: 800;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border: none;
  border-radius: 14px;
}
.parainputsha {
  display: block;
  width: 19em;
  height: 40px;
  margin-left: 2em;
  margin-bottom: 25px;
  margin-top: -50px;
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.parainputsmestextay {
  display: block;
  width: 90%;
  height: 50px;
  margin-left: 10px;
  margin-top: 5px;
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  background-color: #ffffff;
  background-image: none;
  border-radius: 5px;
}
.parainputsmestexta {
  display: block;
  width: 90%;
  height: 50px;
  margin-left: 10px;
  margin-top: 5px;
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.parainputsmestext {
  display: block;
  width: 90%;
  height: 100px;
  /* margin-left: 2em;
  margin-top: 10px; */
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.appperson {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  padding-left: 30px;
  color: #1c5685;
}
.parainputsmes {
  display: block;
  width: 19em;
  height: 100px;
  margin-left: 2em;
  margin-top: 10px;
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.parainputsed {
  display: block;
  width: 19em;
  height: 40px;
  margin-left: 2em;
  margin-top: 10px;
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  border: none;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.myappr {
  display: flex;
}
.report {
  display: flex;
}
.parainputside {
  width: 20em;
  margin-left: auto;
  margin-right: 40px;
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  /* background-color: rgb(220, 225, 233); */
  background-image: none;
  border-radius: 5px;
}
.parainputsapphf i {
  font-size: 30px;
}
.parainputsapphf {
  margin-top: 10px;
  margin-left: 5px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.parainputsapp {
  display: block;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 8px 10px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.parainputsreport {
  display: flex;
  margin-left: 2em;
  margin-top: 10px;
  padding: 5px 2px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.parainputs {
  /* display: block; */
  width: 19em;
  height: auto;
  margin-left: 2em;
  margin-top: 10px;
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  background-color: rgb(220, 225, 233);
  background-image: none;
  border-radius: 5px;
}
.logininputsele {
  display: block;
  width: 15em;
  height: 10px;
  margin-left: 2em;
  margin-bottom: 25px;
  padding: 15px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgb(129, 143, 163);
  border-radius: 14px;
}
.logininputs {
  display: block;
  width: 18em;
  height: 10px;
  margin-left: 2em;
  margin-bottom: 15px;
  padding: 15px 12px;
  font-size: 14px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgb(129, 143, 163);
  border-radius: 14px;
}

.activeuser {
  width: 100%;
  padding: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #0c3546;
  border-radius: 8px;
  background: rgba(228, 226, 226, 0.13);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(30px);
}

.activeuserr {
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  color: #0c3546;
  border-radius: 8px;
  background: rgba(228, 226, 226, 0.13);
  border: 2px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(30px);
}
.activeuserpro {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  color: #0c3546;
  border-radius: 8px;
  background: rgba(228, 226, 226, 0.13);
  border: 2px solid rgba(29, 28, 28, 0.4);
  backdrop-filter: blur(30px);
}
.conteinputi {
  font-style: normal;
  margin-left: 90px;
  font-weight: 550;
  font-size: 14px;
  line-height: 46px;
  text-decoration: underline;
  color: #3b3939;
}
.conteinputitorleft {
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 46px;
  padding-left: 34%;
  color: #16977f;
}
.conteinputitor {
  font-style: normal;
  font-weight: 550;
  font-size: 12px;
  line-height: 46px;
  padding-left: 32%;
  color: #16977f;
}
.conteinpuunitta {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  padding-top: 15px;
  line-height: 26px;
  padding-left: 10px;
  color: #4a4a4b;
}
.conteinpu {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  padding-left: 5px;
  color: #4a4a4b;
}
.conten {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  padding-left: 20px;
  color: #4a4a4b;
}
.contenblue {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #3333da;
}
.uni {
  padding-left: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 800;
}
.alldeta {
  margin-left: auto;
  margin-right: auto;
}
.titlesta {
  text-align: center;
  padding: 30px;
}
.unityli {
  margin-left: 70px;
  color: #000000;
  font-size: 13px;
  font-weight: 800;
}
.unity {
  padding-left: 5px;
  color: #000000;
  font-size: 14px;
  font-weight: 800;
}

.newside {
  border-radius: 10px;
  height: 264px;
  width: 531px;
}
.news {
  border-radius: 10px;
  height: 264px;
  width: 561px;
  margin-left: 30px;
}

.cardphoto {
  background: #ffffff;
  box-shadow: 0px 36px 145px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: none;
  width: 600px;
  margin-left: 25px;
  padding: 25px;
  margin-top: -20px;
}
.titleceo {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #1c2834;
}
.name {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #16977f;
}
.cardworklist {
  border-top: 5px solid #16977f;
  background: #ffffff;
  box-shadow: 0px 0px 377px rgba(0, 0, 0, 0.1);
  /* width: 30%; */
  margin-right: 30px;
  border-radius: 10px;
}
.profile-headerstu {
  text-align: center;
  display: flex;
}
.userprofile {
  height: 60px;
  width: 55px;
  cursor: pointer;
  background: #f4f5f6;
  border-radius: 10px;
  padding: 10px;
  margin: 40px 40px 0px 40px;
}
.publish {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  letter-spacing: 0.2px;
  padding-left: 40px;
  color: #00cc6a;
}
.detoal {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding-left: 30px;
  letter-spacing: 0.2px;
  color: #a0a0a0;
}
.containerusercard {
  overflow-y: scroll;
  height: 320px;
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.cardtable {
  background: #ffffff;
  padding: 20px;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
}
select {
  padding-left: 1em;
}
.categoryhah {
  background: #f4f5f6;
}
.dataprofile {
  margin-top: 40px;
}
.datacoin {
  padding-top: 5px;
  padding-left: 20px;
}
.coinprofile {
  height: 60px;
  width: 55px;
  cursor: pointer;
  /* background: #f4f5f6; */
  border-radius: 10px;
  /* padding: 10px;
  margin: 40px 40px 0px 40px; */
}
.userproname {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  color: #252733;
}
.userpronopena {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  color: #9295a1;
  margin-top: -10px;
  background: #ffffff !important;
}
.userinfonpadd {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  font-size: 12px;
  margin-left: 0px;
  /* background-color: #a4f0e2; */
  color: #202020;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: -65px;
}
.usercapitalonou {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  margin-left: 0px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
}
.userinfonou {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  font-size: 12px;
  margin-left: 0px;
  /* background-color: #a4f0e2; */
  color: #202020;
  margin-top: 0;
  margin-bottom: 0;
}
.userpronouwere {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  color: #9295a1;
  border: 1px solid #a82808;
  cursor: pointer;
}
.userpronouwe {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  color: #9295a1;
}
.usercapitalwe {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  color: #ffff;
  margin-top: 0;
  margin-bottom: 0;
  /* margin-left: -15px; */
}
.userinfowe {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  color: #6a6b6d;
  margin-top: 0;
  margin-bottom: 0;
}

.userpronou {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  color: #252733;
}
.linecomplist {
  border: 0.5px solid #0f0f0f1e;
  width: 100%;
  margin-top: -2px;
}
.userpro {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  margin-top: -14px;
  color: #000000;
}
/* .Nav_right .Nav__link:hover,
.active {
  transition: all 0.5s;
  background-color: white;
  border-radius: 49px 0px 0px 60px;
  padding-left: 20px;
  color: #061627;
} */
.Nav__item {
  display: flex;
  justify-items: space-between;
}
.main {
  margin-top: 20vh;
}
.page-wrapper .sidebar-wrapper {
  width: 220px;
  height: 100%;
  background: rgba(6, 22, 39, 0.99);
  backdrop-filter: blur(15px);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.3s ease, width 0.3s ease;
  border-radius: 9px 9px 9px 0px;
}
.page-wrapper .sidebar-wrapper .sidebar-brand {
  margin: 0 0 1rem 0;
  /* background: rgba(6, 22, 39, 0.99); */
  overflow: hidden;
}
.logdashboard {
  color: #fff;
  margin-top: 50px;
  padding-left: 15px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: center;
  text-decoration: underline #16977f;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
.searchmem {
  display: flex;
  /* padding-left: 60%; */
  justify-content: end;
}
.searchadd {
  display: flex;
  /* padding-left: 38%; */
  justify-content: end;
}
.searchaddRoun {
  display: flex;
  /* padding-left: 38%; */
  justify-content: end;
}
.searchloan {
  display: flex;
  margin-left: 80%;
}
.searche {
  display: flex;
  /* padding-left: 45%; */
  justify-content: end;
}
.searchexp {
  display: flex;
  /* padding-left: 70%; */
  justify-content: end;
}

.searchaddrou {
  display: flex;
  padding-left: 65%;
}
.detailedcon {
  margin-left: 30px;
  margin-top: -15px;
  display: flex;
  padding-bottom: 20px;
}
.testi {
  color: #000000;
}
/* 
.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
} */
.containeruserscroll {
  align-items: stretch;
  margin-left: 20px;
  margin-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.membadd {
  display: flex;
  justify-content: left;
}

.membaddd {
  display: flex;
  justify-content: end;
}
.membaddy {
  display: flex;
  justify-content: left;
}
.contentmemb {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 10px;
  margin-top: 12px;
  color: #1e1e1f;
}

.totaldetails {
  display: flex;
  background-color: #e7e7eb;
  padding: 5px;
  width: 50%;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.totaldeta {
  display: flex;
  background-color: #e7e7eb;
  margin-left: 30px;
  height: 35px;
  width: 50%;
  border-radius: 10px;
}
.totaldetaer {
  display: flex;
  border: 1px solid #16977f;
  background: transparent;
  margin-left: 30px;
  height: 35px;
  border-radius: 10px;
}
.contrilink {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-left: 20px;
  padding-bottom: 10px;
  color: #4a4a4b;
  cursor: pointer;
}
.Editbuttonstuword {
  margin-left: 15px;
  text-align: left;
  font-size: 12px;
  font-weight: 800;
  background-color: #16977f;
  color: #fff;
  padding: 8px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
}
.Editbuttonstuwo {
  margin-left: 15px;
  text-align: left;
  background-color: #16977f;
  font-size: 12px;
  font-weight: 800;
  padding: 5px;

  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.Editbuttonstu {
  margin-left: 15px;
  text-align: center;
  font-size: 14px;
  color: #6a6b6d;
  border-radius: 5px;
  cursor: pointer;
}

.contrilink:hover {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  text-decoration-line: underline;
  line-height: 16px;
  margin-left: 20px;
  padding-bottom: 10px;
  color: #4472c4;
  cursor: pointer;
}
.loginsearchfaro {
  height: 30px;
  width: 14rem;
  font-size: 14px;
  color: #323232;
  margin-left: auto;
  padding: 15px 15px;
  line-height: 20px;
  border: 1px solid rgb(204, 194, 194);
  border: 0;
  background: #f1f8ff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 10px;
  box-shadow: 0 0 5px #e5e9ec;
  border: 1px solid #d7dbe2;
}
.loginsearchfayui {
  /* width: 20em; */
  font-size: 14px;
  color: #323232;
  padding: 5px;
  line-height: 20px;
  border: none;
  border: 0;
  background: transparent;
}

.print i {
  color: #16977f;
  font-size: 28px;
  cursor: pointer;
}
.loginsearchfa {
  height: 40px;
  /* width: 20em; */
  font-size: 14px;
  color: #323232;
  margin: 10px;
  padding: 20px 15px;
  line-height: 20px;
  border: 1px solid rgb(204, 194, 194);
  border: 0;
  background: #f1f8ff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 10px;
  box-shadow: 0 0 5px #e5e9ec;
  border: 1px solid #d7dbe2;
}
.btndistrer {
  color: #ffffff;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: auto;
  margin-bottom: 10px;
  height: 40px;
  background: #16977f;
  border: 1px solid rgb(204, 194, 194);
  /* background: transparent; */
  padding: 0px 30px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 5px #e5e9ec;
  border: 1px solid #d7dbe2;
}
.btnd {
  color: #121213;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 10px;
  background: transparent;
  border: 1px solid #16977f;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 7px;
  /* border: 1px solid #d7dbe2; */
}

.btndistr {
  color: #ffffff;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin: 5px;
  background: #16977f;
  border: 1px solid rgb(204, 194, 194);
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 7px;
  box-shadow: 0 0 5px #e5e9ec;
  border: 1px solid #d7dbe2;
}
.tableequity {
  margin-left: 200px;
}
.userprony {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  background-color: #16977f;
  font-size: 12px;
  color: #ffffff;
}
.userpronyblue {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  background-color: #16977f;
  font-size: 12px;
  color: #ffffff;
}

.userproneruloan {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  background-color: #97cc97;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: #424040;
}
.userproneruloan:hover {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  background-color: #97cc97;
  font-size: 12px;
  padding: 4px;
  cursor: pointer;
  text-decoration: underline;
  color: #03254c;
}
.userproneru {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  background-color: #97cc97;
  font-size: 12px;
  color: #424040;
}
.userpron {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  background-color: #16977f;
  font-size: 12px;
  color: #ffffff;
}
.userinfostatdy {
  font-style: normal;
  text-align: left;
  padding-left: 35%;
  line-height: 5px;
  color: #631a1a;
  font-weight: 500;
  font-size: 12px;
  font-weight: 700;
}
.userinfostat {
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  /* padding-left: 35%; */
  line-height: 5px;
  color: #333232;
  font-weight: 500;
  font-size: 12px;
}
.userin {
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  width: 140px;
  line-height: 20px;
  color: #333232;
  font-weight: 500;
  font-size: 12px;
}
.userinfostate {
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  color: #16977f;
  line-height: 5px;
  margin-left: 35%;
  font-weight: 600;
  font-size: 12px;
}
.userinfostatde {
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  color: #201e1e;
  line-height: 5px;
  /* margin-left: 35%; */
  font-weight: 700;
  font-size: 12px;
}
.userinfo {
  font-family: sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  /* color: #ffffff; */
}

.table th {
  padding-bottom: 10px;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border-bottom: 1px solid #d3d9e0;
  text-align: center;
  font-size: 12px;
}

.table thead th {
  padding-top: 10px;
  font-weight: 600;
  border-bottom: 1px solid #d3d9e0;
  text-align: center;
  font-size: 12px;
  vertical-align: middle;
}
.sent {
  /* padding: 10px; */
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #298a5b;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.succpar {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: rgba(73, 75, 78, 0.6);
  flex: none;
  order: 2;
  flex-grow: 0;
}

.table td {
  border-top: 1px solid #d3d9e0;
  padding: 0px;
}
.sidebar-title {
  width: 200px;
  text-align: center;
  margin-left: 20px;
  margin-top: 40px;
  color: #fff;
  /* background: rgba(205, 221, 222, 0.67); */
  border-radius: 39px 0px 0px 39px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 19px;
}
.logo- {
  margin-top: 20px;
  height: 50px;
  border-radius: 50px;
}
.sidebar-title i {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 20px;
}
.Nav__item {
  display: flex;
  justify-items: space-between;
  margin-top: -15px;
}
.login {
  padding-top: 50px;
  height: 100vh;
  width: 100vw;
}
.tslog {
  font-size: 34px;
  padding-top: 10px;
  line-height: 44px;
}
.caerdlog {
  top: 50px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  /* height: 60%; */
  padding: 30px;
  /* background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08); */
  border-radius: 20px;
}
.btnapplsign {
  appearance: none;
  background-color: #16977f;
  border: none;
  border-radius: 4px;
  color: inherit;
  font-weight: 500;
  font-size: 18px;
  inline-size: 90%;
  margin: 0;
  padding: 10px;
  text-overflow: ellipsis;
  color: #ffffff;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.logintitle {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  font-size: 35px;
  line-height: 42px;
  color: #000000;
}
.addinfo {
  margin-left: auto;
  float: right;
}
.addin {
  margin-top: 50px;
}
.addSchool {
  background: #081829;
  border-radius: 12px;
  color: #fff;
  padding: 6px;
  width: 145px;
  margin-left: 20px;
  border: none;
}
.addnewbus {
  background: #081829;
  border-radius: 12px;
  color: #fff;
  padding: 6px;
  width: 175px;
  margin-left: 20px;
  border: none;
}
.profile {
  border: 50px;
  background-color: #081829;
  color: #fff;
  height: 40px;
  width: 40px;
  font-size: large;
  font-size: 1em;
  margin-left: 20px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}
.searching {
  border: 50px;
  background-color: #fea104;
  color: #fff;
  height: 40px;
  width: 40px;
  font-size: large;
  font-size: 1em;
  margin-left: 20px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}

.username {
  margin-top: -3px;
  padding-left: 10px;
  font-size: 14px;
}
.busesoverl {
  padding-left: 10px;
  margin-top: -3px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  color: rgba(63, 63, 63, 0.96);
}
.schooloverl {
  padding-left: 10px;
  float: right;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: rgba(63, 63, 63, 0.96);
}
.cardrow {
  margin-right: 0px;
  margin-top: 5px;
}

.rowbusoverall {
  background: rgba(205, 221, 222, 0.2);
  backdrop-filter: blur(15px);
  padding: 10px;
  margin-top: 20px;
  width: 380px;
  border-radius: 15px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.rowschooll {
  background: rgba(205, 221, 222, 0.2);
  backdrop-filter: blur(15px);
  padding: 10px;
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.rowscomp {
  background: #f29800;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 17px;
  padding: 15px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.rowspro {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  background: rgba(242, 152, 0, 0.15);
  border-radius: 20px;

  padding: 15px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
}
.rowsco {
  padding: 15px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 10px;
  height: 55px;
}
.rowsdet {
  height: 55px;
}
.rowswtdet {
  border: 1px solid rgba(205, 221, 222, 0.36);
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  border-radius: 10px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 55px;
}
.rowsdetcomp {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 20px;
  padding: 3px;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  height: 55px;
}
.rowsdet .rowbusoveralltitle {
  margin-left: 10px;
  font-family: " Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #3f3f3f;
}
.contentdetcomp {
  color: #fff;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}
.contentdetpro {
  color: #fea104;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}
.contentdet {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 15px;
  line-height: 24px;
  padding: 10px;
  margin-top: 12px;
  color: #3f3f3f;
}
.contedertbox {
  color: #3f3f3f;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
}
.poweredse {
  margin-left: 100px;
}

.nbrbus {
  margin-left: 100px;
  font-family: " Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #777777;

  line-height: 24px;
}
.nbrschool {
  margin-left: 30px;
  font-size: 15px;
  font-family: " Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;

  line-height: 24px;
}

.tranctionheader {
  margin-left: 50px;
}
.schheader {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #3f3f3f;
}
.infoheader {
  margin-left: 40px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #3f3f3f;
}
.overlaysum {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 10px;
}
.caerd {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-left: 2px;
}
.cardsum {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-left: 2px;
}
.carddetails {
  background: #ffffff;
  border-radius: 8px;
  width: 105%;
  height: 100px;
  /* box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.26); */
  color: #fff;
  margin-right: 8px;
  border: 1.5px solid #dfe0eb;
}
.carddeta {
  background: #081829;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  color: #fff;
  margin-left: 25px;
}

.newschool i {
  background: rgba(255, 255, 255, 0.58);
  backdrop-filter: blur(15px);
  border-radius: 9px;
  height: 40px;
  width: 40px;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 2px;
  font-size: 20px;
}
.pgschl {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #cdddde;
  margin-top: -9px;
  margin-left: 10px;
}
.pgnbschl {
  font-size: 24px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  color: #fff;
  margin-top: -10px;
  margin-left: 10px;
}
.pg1schl {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #cdddde;
  margin-left: 10px;
}
.imgbus {
  height: 190px;
}
.dangerdemor {
  color: #f29800;
  text-align: center;
}
.overlay {
  position: absolute;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 10px;
}
.detailsbus1 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #424646;
  padding: 10px;
  text-align: center;
}
.detailsbusnofond {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10.5px;
  line-height: 15px;
  padding: 10px;
  margin-top: 5px;
  color: #ff0000;
}
.detailsbus {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10.5px;
  line-height: 15px;
  padding: 10px;
  margin-top: 5px;
  color: #323232;
}
.migrate {
  display: flex;
  padding-left: 160px;
}
.detailsbu {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  padding-top: 10px;

  color: #424646;
}
.imgkaty {
  padding-top: 5px;
  height: 50px;
  width: 50px;
  border: 100%;
}
.rowsdet {
  background: rgba(205, 221, 222, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  padding: 0px;
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 25px;
}
.rowcodet {
  padding: 5px;
  margin-top: 2px;
  margin-left: 5px;
}
.headercomp {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  margin-left: 20px;
  color: #081829;
}
.imgparahead {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #829293;
}
.imgparahe {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #fea104;
}
.imgparabod {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #323232;
  display: block;
}
.imgpara {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 27px;
  color: #323232;
  display: block;
  margin-left: 20px;
}
.imgparabody {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #ffffff;
  display: block;
}
.imginfo {
  padding: 30px;
}
.imginfog {
  padding-left: 30px;
}
.headercompdeta {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  padding-bottom: 10px;
  color: #081829;
}
.detailsdest {
  background: rgba(254, 161, 4, 0.19);
  backdrop-filter: blur(18px);
  border-radius: 9px;
  border: 0px;
  text-align: center;
  padding: 10px 15px;
  margin-top: 15px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
}
.colcard {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
}
.coltrack {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
}
.Busplate {
  background: #ffffff;
  border-radius: 15px;
  border: 0;
  padding: 10px;
  margin-left: 20px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
}

.selectcomp {
  color: #829293;
}
.Busplate::placeholder {
  color: #000000;
}
.user {
  margin-top: -40px;
}
.itemok {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.item {
  display: flex;
  border-bottom: 1px solid #dfe0eb;
}
.viewdet {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: auto;
  line-height: 40px;
  color: #16977f;
}
.viewdetnum {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 15px;
  line-height: 20px;
  margin-left: auto;
  color: #9fa2b4;
}
.viewdetint {
  margin-left: auto;
  color: #ffffff;
  padding: 5px 20px;
  background: #fec400;
  border-radius: 8px;
  cursor: pointer;
}
.viewedit {
  margin-left: 25px;
  color: #ffffff;
  margin-top: 15px;
  padding: 5px 20px;
  background: #16977f;
  border-radius: 5px;
  cursor: pointer;
}

.ansewq {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  color: #16977f;
}

select {
  padding-left: 10px;
}
.ques {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}
.asktit {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 50px;
  line-height: 150px;
  color: #000000;
}
.bodyasky {
  text-align: center;
  padding: 35px;
  display: flex;
  border: 3px solid #dedede;
  border-radius: 6px;
  margin-bottom: 20px;
}
.viewdpubl {
  border: 2px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  color: #000000;
  margin-top: 15px;
  padding: 5px 20px;
}
.newsectionadd {
  padding: 30px;
}
.btnaddjob {
  margin-top: 20px;
  font-size: 25px;
  padding-top: 5px;
  width: 50px;
  height: 50px;
  color: #ffff;
  text-align: center;
  background: #202a4d;
  border-radius: 50px;
}
.btnaddblogaddsd {
  font-size: 16px;
  margin-top: 8px;
  margin-left: 78%;
  padding: 2px;
  width: 29px;
  height: 29px;
  color: #ffff;
  text-align: center;
  background: #16977f;
  border-radius: 50px;
}
.btnaddblogadds {
  font-size: 16px;
  margin-left: 20px;
  padding-top: 3px;
  width: 25px;
  height: 28px;
  color: #ffff;
  text-align: center;
  background: #16977f;
  border-radius: 50px;
}
.btnaddblogme {
  margin-top: 10px;
  margin-left: 90%;
  font-size: 16px;
  padding-top: 3px;
  width: 30px;
  height: 30px;
  color: #ffff;
  text-align: center;
  background: #16977f;
  border-radius: 50px;
  cursor: pointer;
  cursor: pointer;
}
.btnaddblog {
  /* margin-top: 8px; */
  align-self: center;
  font-size: 18px;
  padding: 6px 10px;
  width: 40px;
  height: 40px;
  color: #ffff;
  text-align: center;
  background: #16977f;
  border-radius: 50px;
  cursor: pointer;
  cursor: pointer;
}
.viewedel {
  margin-left: 10px;
  color: #ffffff;
  margin-top: 15px;
  padding: 5px 20px;
  background: #d11a2a;
  border-radius: 5px;
  cursor: pointer;
}

/* .viewdetcli {
  margin-left: auto;
  color: #ffffff;
  margin-top: 15px;
  font-size: 18px;
  padding: 5px 20px;
  background: #29cc97;
  border-radius: 8px;
  cursor: pointer;
} */
.viewdetcli {
  color: #ffffff;
  font-size: 14px;
  font-family: sans-serif;
  padding: 10px 20px;
  background: #16977f;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.viewdetclioninf {
  color: #ffffff;
  font-size: 10px;
  font-family: sans-serif;
  vertical-align: middle;
  margin-top: 15px;
  padding: 5px 5px;
  border-color: #16977f;
  background: #16977f;
  border-radius: 5px;
  cursor: pointer;
}
.viewdetclion {
  color: #ffffff;
  font-size: 10px;
  margin-top: 15px;
  font-family: sans-serif;
  padding: 5px 20px;
  border-color: #16977f;
  background: #16977f;
  border-radius: 5px;
  cursor: pointer;
}
.log {
  display: none;
}
.lebelenterlog {
  display: block;
  font-weight: 600;
  transition: color 0.33s ease;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-top: 5px;
  width: 100%;
  font-size: 14px;
  color: #263a43;
}
.lebelenter {
  margin-left: 30px;
  display: block;
  font-weight: 600;
  transition: color 0.33s ease;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-top: 5px;
  font-size: 14px;
  color: #263a43;
}
.rowuserd {
  margin-right: 500px;
}
.viewdetclionedite {
  color: #16977f;
  font-size: 14px;
  width: 70px;
  text-align: center;
  margin-left: 0px;
  font-family: sans-serif;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.viewdetclionedit {
  color: #ffffff;
  font-size: 13px;
  width: 70px;
  text-align: center;
  margin-left: 60px;
  margin-top: 20px;
  font-family: sans-serif;
  padding: 3px 10px;
  background: #16977f;
  border-radius: 5px;
  cursor: pointer;
}

.viewdetclionmemb {
  color: #ffffff;
  font-size: 14px;
  width: 100px;
  text-align: center;
  margin-left: 75%;
  margin-top: 10px;
  font-family: sans-serif;
  padding: 3px 10px;
  background: #16977f;
  border-radius: 5px;
  cursor: pointer;
}

.cardtablecapital {
  padding-left: 100px;
  padding-right: 100px;
}
.cardCapitalvog {
  padding-left: 200px;
  padding-right: 100px;
}
.viewdetapp {
  margin-left: auto;
  cursor: pointer;
  color: #9fa2b4;
  margin-top: 15px;
  padding: 5px 20px;
  background: #f0f1f7;
  border-radius: 8px;
}
.todayha {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #9fa2b4;
}
.itemtitle {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  padding-top: 15px;
  color: #252733;
}

.logout {
  background: #081829;
  border-radius: 12px;
  margin-left: 20px;
}
.logout:hover {
  background-color: #16977f;
  border: 1px solid #16977f;
}
.iconlogout i {
  font-size: 30px;
  margin-left: 20px;
}
.Map {
  position: relative;
  background: rgba(255, 255, 255, 0.58);
  backdrop-filter: blur(15px);
  border-radius: 9px;
}
.rowmapnext {
  top: 395px;
  position: relative;
  margin-right: 20px;
}
.summa {
  text-align: end;
  color: #ffffff;
}
.trackcontent {
  margin-top: 400px;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  margin-left: 15px;
  padding: 30px;
  padding-bottom: 20px;
}
.seachbt {
  float: left;
}

.trackmap {
  margin-right: 10px;
  border-radius: 10px;
  padding-top: 10px;
}
.imagemen {
  width: 50px;
  height: 50px;
}
.locationcircle {
  width: 45px;
  margin-left: 7px;
  height: 45px;
  text-align: center;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 1px 0 rgb(255 255 255 / 20%);
  border-radius: 50%;
  background: rgba(254, 161, 4, 0.19);
  backdrop-filter: blur(18px);
}
.locationcircle i {
  color: #fea104;
  font-size: 30px;
  padding: 5px;
}
.cardboxes {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  padding: 20px;
}
.peacetitle {
  height: 120px;
  text-align: center;
  padding-top: 15px;
  background: #fea104;
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;
  color: #01507a;
}
.peacepara {
  text-align: center;
  padding-top: 20px;
  color: #fff;
}
.peacepic {
  width: 80px;
  height: 80px;
}
.peaceimg {
  text-align: center;
  margin-top: -40px;
}
.stus {
  font-size: 8px;
  color: rgba(63, 63, 63, 0.96);
}
.in i {
  float: right;
  margin-right: 10px;
}
.skltit {
  padding-left: 50px;
}
.searchoverall {
  padding: 13px 15px;
  margin-left: 210px;
  color: #333333;
  line-height: 20px;
  width: 302px;
  height: 45px;
  background: #ffffff;
  border-radius: 15px;
  border: 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  box-shadow: 0 0 15px #e5e9ec;
  border-bottom: 1px solid #d7dbe2;
}

.searchsquery {
  color: #333333;
  line-height: 20px;
  border: 0;
}
.contper {
  margin-left: 10px;
  display: block;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  /* margin: 50px; */
  width: 90%;
  border-top: 1px solid #e1d8d8;
  border-bottom: 1px solid #e1d8d8;
  /* border-left: 1px solid #e1d8d8; */
}
.contsal {
  display: block;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  /* margin: 50px; */
  width: 90%;
  border-top: 1px solid #e1d8d8;
  border-bottom: 1px solid #e1d8d8;
  border-left: 1px solid #e1d8d8;
}
.contcount {
  margin-left: 50px;
  display: block;
  padding-top: 10px;
  /* padding-bottom: 10px; */
  /* margin: 50px; */
  width: 90%;
  border-top: 1px solid #e1d8d8;
  border-bottom: 1px solid #e1d8d8;
  border-left: 1px solid #e1d8d8;
  border-right: 1px solid #e1d8d8;
}

.activejobs {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  padding-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 42px;
  letter-spacing: 0.2px;
  color: #000000;
}

.descr {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #9fa2b4;
}

.numberzero {
  font-family: "Mulish", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  color: #1c2834;
}
.tooglebutton {
  border: 0;
  margin-left: 10px;
}
.toggle i {
  color: #081829;
  font-size: 25px;
  position: fixed;
}

.blacklivesmatters {
  background-color: #ffffff;
}
.blacklives {
  padding-top: 108px;
  background-color: #ffffff;
  background-size: cover;
}
.matters {
  font-size: 30px;
  font-weight: bold;
  color: #0c3546;
  text-align: center;
  font-weight: 800;
  padding-bottom: 10px;
  line-height: 56px;
  letter-spacing: -0.04px;
}

.paranoid {
  font-size: 15px;
  color: #0c3546;
  text-align: center;
  font-weight: 400;
  line-height: 26px;
}

.linehre {
  width: 70%;
  text-align: center;
  margin-top: 2rem;
  padding-bottom: 108px;
  margin-left: auto;
  margin-right: auto;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.page-wrapper.enlarged .page-content {
  padding-left: 0;
}
.page-wrapper.enlarged .sidebar-wrapper {
  width: 0;
}
.page-wrapper.enlarged .sidebar-title {
  display: none !important;
}
.hedsea {
  margin-left: 200px;
  margin-top: -40px;
}
.pagi {
  color: #e5e9ec;
}
.dupa {
  margin-left: 40px;
}
.closeModale {
  border: 50px;
  background: #ffffff;
  height: 20px;
  width: 20px;
  margin-top: 7px;
  float: right;
  font-size: large;
  font-size: 1em;
  margin-right: 10px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}
.closeModalero {
  border: 50px;
  background: #16977f;
  height: 20px;
  width: 20px;
  color: #fff;
  margin-top: 2px;
  float: right;
  font-size: large;
  font-size: 1em;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}

.closeModal {
  border: 50px;
  background: #ffffff;
  height: 20px;
  width: 20px;
  float: right;
  font-size: large;
  font-size: 1em;
  margin-top: 5px;
  margin-left: 20px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 15px 15px 15px 15px;
}
.closeModal i {
  font-size: 15px;
  color: #061627;
}

.addschoolmod {
  color: #ffffff;
  padding: 20px 0px 10px 50px;
}

.modal-body {
  position: fixed;
  background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
}
.btnhome {
  padding: 25px;
  width: 100%;
}
.btnbook {
  color: #202a4d;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border: 3px solid #444444;
  background: transparent;
  line-height: 44px;
  padding: 3px 40px;
  cursor: pointer;
  border-radius: 3px;
}
.btnappl {
  background: #202a4d;
  color: #ffffff;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 44px;
  padding: 6px 40px;
  border: none;
  width: 85%;
  margin-top: 70px;
  border-radius: 3px;
  cursor: pointer;
}
.contrub {
  background: #1c2834;
  color: #ffffff;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 44px;
  padding: 0px 30px;
  border: none;
  margin-left: 30px;
  margin-bottom: 10px;
  border-radius: 3px;
  cursor: pointer;
}
.contrub:hover {
  background: #1c2834;
  color: #16977f;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 44px;
  padding: 0px 30px;
  border: none;
  margin-left: 30px;
  margin-bottom: 10px;
  border-radius: 3px;
  cursor: pointer;
}
.need-help.tds-text--center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #5c5e62;
  font-weight: 500;
  margin-top: 30px;
  margin-inline-end: auto;
  margin-inline-start: auto;
  text-align: center !important;
  line-height: 20px;

  text-decoration: underline;
  cursor: pointer;
}
.need-help.tds-text--center:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-inline-end: auto;
  margin-inline-start: auto;
  text-align: center !important;
  line-height: 25px;
  color: #121213;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.linebreak {
  color: #5c5e62;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 40px auto;
  white-space: nowrap;
}
.linebreak::before,
.linebreak::after {
  content: "";
  display: inline-block;
  width: 38%;
  height: 2px;
  margin: 0 5px;
  background-color: #5c5e62;
  animation: none !important;
  transition: none !important;
}
.tds-text--500 {
  font-weight: 500 !important;
}
.formModaltes {
  appearance: none;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  color: inherit;
  font-weight: 500;
  inline-size: 90%;
  margin: 0;
  padding: 12px;
  text-overflow: ellipsis;
}
.formModalase {
  display: block;
  padding: 5px 15px;
  line-height: 20px;
  width: 85%;
  height: 120px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  background: #ffffff;
  -moz-border-radius: 50px;
}
.formModalbus {
  display: block;
  padding: 5px 15px;
  line-height: 20px;
  width: 402px;
  height: 50px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  background: #ffffff;
  -moz-border-radius: 50px;
}
.formModal {
  display: block;
  padding: 5px 10px;
  line-height: 20px;
  width: 252px;
  height: 40px;
  border: 0.6px solid #b1b1b1;
  border-radius: 6px;
  font-size: 14px;
  color: #555;
  background: #ffffff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 10px;
}
/* .formdata {
  margin-top: 40px;
  margin-left: 30px;
  margin-right: auto;
}  */
/* .lebelenter {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  font-size: 16px;
  color: #263a43;
} */
.labels {
  position: relative;
  margin-left: 30px;
  top: 20px;
  background-color: #061627;
  color: #cdddde;
}
.apmodal {
  padding-top: 30px;
  padding-right: 100px;
}
.btnmodal {
  background: #fea104;
  border-radius: 4px;
  border: 0;
  color: #fff;
  position: absolute;
  top: 132%;
  width: 90%;
  right: auto;
  left: auto;
  padding: 10px;
  font-size: 16px;
}
.addreportbuton {
  margin-top: 12%;
  padding: 8px;
  font-size: 16px;
  background: #fea104;
  border-radius: 4px;
  width: 90%;
  border: 0;
  right: auto;
  left: auto;
}
.paginationcl {
  padding: 20px;
}
.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}
#header .logo img {
  margin-top: 10px;
  max-height: 90px;
  margin-right: 80px;
}

.logo {
  margin-top: 10px;
  margin-left: 30px;
}

.navheader {
  margin: 0;
}

.titlepro {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 56px;
  color: #03254c;
  text-align: left;
  margin-right: 50px;
  margin-top: -10px;
}
.track {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #333435;
}
.trackgps {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #333435;
  line-height: 33px;
}
.devicetrack {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #333435;
  margin-top: -30px;
}
.titletrackerfear {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
  text-align: left;
  margin-right: 50px;
}
.alltracker {
  margin-left: 200px;
}
.about {
  width: 100%;
  /* padding: 6vw 0; */
  /* position: relative; */
  margin-bottom: 108px;
  background-color: #ededee;
}
.logoparag {
  font-family: "georgia";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 98%;
  color: #16977f;
  padding-top: 28px;
  margin-left: -40px;
}
.titleabout {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  color: #03254c;
  text-align: left;
  padding-bottom: 30px;
}
.titleser {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  color: #000000;
  text-align: center;
  padding-bottom: 30px;
  margin-left: 10px;
}

.Lign {
  border: 4px solid #16977f;
  height: 40px;
  margin-top: 10px;
}
.forgotpassword {
  color: #0b4e5f;
  text-align: center;
  font-size: 20px;
  text-decoration: underline;
  padding: 12px;
}
.Lignser {
  border: 4px solid #16977f;
  height: 40px;
  margin-top: 10px;
}
.Ligncore {
  border: 4px solid #ffffff;
  height: 40px;
  margin-top: 6px;
}
.Lignpri {
  border: 4px solid #16977f;
  height: 40px;
  margin-top: 6px;
}
.Core {
  justify-content: center;
}

.paratracker {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 49px;
  color: #333435;
  text-align: left;
  justify-content: center;
  margin-bottom: 20px;
}
.parasecure {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #333435;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}
.parasecurede {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #333435;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
}
.paraRent {
  margin-top: -30px;
  font-size: 18px;
  line-height: 45px;
}
.headercta {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 56px;
  color: #ffffff;
  text-align: center;
  padding-bottom: 30px;
  margin-left: 10px;
}
.parabout {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  color: #000000;
  text-align: left;
  padding-bottom: 5px;
}

.rowsize {
  padding-bottom: 40px;
}
.content {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #000000;
  justify-content: center;
}
.trackapbtn {
  background: #9a8dfa;
  box-shadow: 2px 4px 4px rgba(42, 157, 244, 0.18);
  border-radius: 14px;
  color: white;
  border: 0;
  padding: 10px 10px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 30rem;
  margin-left: 7rem;
}
.trackagpsbtn {
  background: #9a8dfa;
  box-shadow: 2px 4px 4px rgba(42, 157, 244, 0.18);
  border-radius: 14px;
  color: white;
  border: 0;
  padding: 10px 10px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15npx;
  margin-top: 30rem;
  margin-left: 13rem;
}
.trackbtnfear {
  background: #9a8dfa;
  box-shadow: 2px 4px 4px rgba(42, 157, 244, 0.18);
  border-radius: 14px;
  color: white;
  border: 0;
  top: 20px;
  padding: 10px 10px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
}

#slider {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
#rightslider {
  animation: 1s ease-out 0s 1 slideInFromRight;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.containerabout {
  background: #16977f;
  padding: 5px;
}
.containerpri {
  background: #16977f;
  padding: 40px;
}
.titlecta {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
}
.circle {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 1.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  text-shadow: 0 1px 0 rgb(255 255 255 / 20%);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.37);
  backdrop-filter: blur(36px);
  margin-left: 20px;
}
.circle i {
  color: #ffffff;
  font-size: 30px;
}
.trackLogo {
  margin-top: 20px;
  height: 100px;
}
.paracta {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.boxpa {
  margin-left: 20px;
}
.boxra {
  margin-left: 10px;
}
.boxes {
  margin-left: -40px;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #a79df7;
}
.number {
  background: #16977f;
  border-radius: 10px;
  color: white;
  padding: 10px 10px;
  font-size: 30px;
}
.headSubser {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #000000;
}
.slider {
  background-color: #978aff;
}
.headubsers {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #000000;
}
.headubsers i {
  color: #a79df7;
}
.trackercoin {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #000000;
}
.paraSubser {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  text-align: left;
}
.trackerSubser {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
  text-align: center;
}
.sercontent {
  padding-left: 20px;
  margin-top: 5px;
}
.procontent {
  padding-left: 10px;
  margin-top: 30px;
}
.boxser {
  padding-bottom: 20px;
}
.boxsersecond {
  margin-top: 40px;
  padding-top: 10px;
  padding-left: 60px;
}
.product {
  height: 100px;
  width: 100px;
  margin: auto;
}

.Productus {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 56px;
  color: #000000;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-left: 10px;
}
.blockright {
  height: 400px;
  width: 400px;
  margin-left: 70px;
}
.car {
  height: 350px;
  width: 500px;
}

.footercard {
  position: fixed;
  background: transparent;
  color: #0c3546;
  font-size: 14px;
  text-align: center;
  padding: 20px 0px 0px 20px;
  bottom: 0;
  width: 100%;
}
.footernav a {
  color: white;
  text-decoration: none;
}
.footernav ul {
  list-style-type: none;
}
.footerimg {
  height: 130px;
}
.contactus {
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #16977f;
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 108px;
}
.form {
  padding: 30px 0px 30px 20px;
}
.btnsend {
  background: #16977f;
  border-radius: 14px;
  border: 0;
  padding: 10px 10px;
  margin-left: 200px;
  color: #ffffff;
  width: 90%;
}
.form-controlin {
  background: rgba(215, 201, 247, 0.24);
  border-radius: 10px;
  border: 0;
  padding: 15px;
  width: 100%;
  margin-left: 160px;
}
.form-controlro {
  background: rgba(215, 201, 247, 0.24);
  border-radius: 10px;
  border: 0;
  padding: 20px;
  width: 100%;
  margin-left: 160px;
}
.getin {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  color: #000000;
  padding: 0px 0px 30px 350px;
}
@media (max-width: 768px) {
  .userinfostatde {
    font-family: sans-serif;
    font-style: normal;
    text-align: left;
    color: #201e1e;
    line-height: 15px;
    /* margin-left: 35%; */
    font-weight: 700;
    font-size: 12px;
  }
  .totuil {
    line-height: 15px;
  }
  .userinfostat {
    font-family: sans-serif;
    font-style: normal;
    text-align: left;
    /* padding-left: 35%; */
    line-height: 10px;
    color: #333232;
    font-weight: 500;
    font-size: 12px;
  }
  .titleabout {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 56px;
    color: #03254c;
    text-align: center;
    padding-bottom: 30px;
  }
  .loginin {
    background-color: #16977f;
    color: #fff;
    border: 2px solid #16977f;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 30%;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  .poweredse {
    margin-left: 30px;
  }
  .logoparag {
    font-family: "georgia";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    /* line-height: 90%; */
    color: #16977f;
    padding-top: 40px;
    /* margin-left: -40px; */
  }

  .navbar-toggler {
    padding: 0;
    border: 0;
    width: 30px;
    height: 30px;
    vertical-align: top;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #16977f;
    position: relative;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    /* background: no-repeat center center; */
    background-size: 100% 100%;
    color: #ffffff;
  }
  .counts {
    padding-bottom: 5rem;
  }
  .contscontac {
    padding-bottom: 25rem;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #665bbf;
    display: none;
  }

  .cardpricing {
    display: block;
  }
  .card {
    display: block;
  }
  section {
    padding: 2px 0;
    overflow: hidden;
  }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
    float: right;
  }
  .Nav:not(.Nav__black) #basic-navbar-nav {
    top: 6rem;
    position: absolute;
    width: 60%;
    left: 20px;
    height: 100vh;
  }
}
.card-title {
  color: #ffffff;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: -30px;
  padding: 30px;
  background: #16977f;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.32);
  border-radius: 0px 0px 14px 14px;
  height: 100px;
}
.card-tit {
  color: #ffffff;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: -30px;
  padding: 30px;
  background: #16977f;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.32);
  border-radius: 0px 0px 14px 14px;
  height: 100px;
}
.cardone {
  background: #ffffff;
  border-radius: 15px;
  width: 300px;
}
.Carres {
  margin-left: 50px;
}
.contentpri {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 5px;
  color: #000000;
}
.contentpris {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 3px;
  color: #000000;
  text-align: center;
}
/* .card {
  background: #ffffff;
  box-shadow: -5px 4px 5px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
} */
.cardpricing {
  justify-content: center;
}
@media (max-width: 768px) {
  /* .cardpricing {
    flex-direction: column;
  } */
  .cardone {
    width: 150%;
    margin-bottom: 100px;
    text-align: center;
  }
  .card {
    /* width: 150%; */
    margin-bottom: 10px;
    text-align: center;
  }

  .banner {
    height: 100vh;
  }
  .form {
    margin-bottom: 40px;
  }
}
.devise {
  padding-top: 8px;
  font-size: 15px;
}

.contentpris h6 {
  color: linear-gradient(180deg, #b18ee1 0%, #da7176 100%);
}
.phone {
  margin-right: 0;
}
.footerul ul {
  list-style-type: none;
  color: white;
}

.footerulsec {
  padding-top: 32px;
  margin-left: -60px;
}
.footeritemheader {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 42px;
  text-decoration-line: none;
  color: #fff;
}
.footeritem {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 150px;
  line-height: 1px;
  text-decoration-line: none;
  color: #fff;
}
.footercontainer {
  border-bottom: 1px solid #e5e5e5;
}
@media (max-width: 768px) {
  .navbar {
    display: flex !important;
    flex-basis: auto;
  }
  .collapse.show {
    padding-top: 30px;
    /* margin-left: %; */
    display: block;
    background-color: #ffffff !important;
    width: -20% !important;
    height: 70vh;
  }
  /* .navbar li {
    width: 100%;
  } */
  .card-tit {
    width: 113px;
    margin-bottom: 40px;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  .contentpri {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 5px;
    color: #000000;
    margin-top: 30px;
  }
  .footerulsec {
    padding-top: 32px;
    margin-left: 25px;
  }

  .footerul {
    margin-left: -60px;
  }
  .rowsize {
    margin-bottom: 20px;
  }
  .banner {
    height: 150vh;
  }
  /* 
  .hero {
    height: 109vh;
  } */
}
.conteinputitle {
  background-color: #16977f;
  color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.modal-body {
  border-radius: 12px;
  background: #ffffff;
  border-radius: 10px;
  /* width: 45%; */
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  top: 45px;
  float: right;
}
/* .formModal {
  display: block;
  padding: 25px 20px;
  line-height: 20px;
  width: 342px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  border: 1px solid #cdddde;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  color: #cdddde;
} */
.formModal::placeholder {
  color: #ffffff;
}
.addmod {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
  color: #ffffff;
}
.btnmodal {
  background: #03254c;
  border-radius: 19px;
  border: 0;
  color: #ffffff;
  padding: 15px 25px;
  margin-left: 10%;
  justify-items: center;
}
.closeModal {
  border: 50px;
  background: #fff;
  height: 20px;
  width: 20px;
  float: right;
  font-size: large;
  font-size: 1em;
  margin-left: 20px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 hsl(0deg 0% 100% / 20%);
  border-radius: 15px 15px 15px 15px;
}
.thetra {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 8px;
  line-height: 80px;

  color: #49494a;
}
.traccker {
  font-family: "Poppins" Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;

  color: #ffffff;
}
.face {
  margin-left: 20px;
  margin-top: 20px;
}
.appstore {
  padding-left: 20px;
}
.carousel-control-prev-icon {
  margin-left: -279px;
  color: #ffffff;
}
.carousel-control-next-icon {
  color: #665bbf;
  margin-left: 109px;
}
.conta {
  margin-left: -60px;
}
.contentpris i {
  color: #da7176;
  font-size: 11px;
}
.jumbotron {
  /* background-image: url("./components/image/road.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 85vh;
  width: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background: rgba(255, 255, 255, 0.93);
  opacity: 0.8;
  z-index: 1;
}
#hero {
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.car {
  height: 110%;
}
.trackerafro {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 80px;
  color: #49494a;
  float: left;
}
.more {
  background-color: #fff;
  color: #666363;
  border: 2px solid #16977f;
  border-radius: 4px;
  padding: 8px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}
.loginin {
  background-color: #16977f;
  color: #fff;
  border: 2px solid #16977f;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 20px;
  transition-duration: 0.4s;
  cursor: pointer;
}
.rowsdet {
  background: rgba(205, 221, 222, 0.2);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  border: 0.3px solid #b7b9b9;
  padding: 0px;
  margin-top: 8px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 8px;
}
.contdensa {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 8px;
  color: #495155;
}
.contdens {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 10px;
  margin-top: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  padding-top: 8px;
  color: #495155;
}

.navbar-light .navbar-nav .nav-link {
  color: hsl(198, 71%, 16%);
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  padding-top: 10px;
  padding-right: 20px;
  /* text-align: center; */
}
.navbar-light .navbar-nav .nav-link:hover {
  text-decoration: underline;
  color: #16977f;
}
.navbar-light .navbar-nav .nav-link:focus {
  outline: none;
  text-decoration: none;
}
.trackerafooter {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 80px;
  color: #fff;
  margin-top: -10px;
  margin-left: 10px;
}

.trackerfear {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 80px;
  color: #49494a;
  margin-right: 330px;
}
.horizo {
  width: 20%;
  height: 1px;
  background: #49494a;
  margin-top: 40px;
  margin-left: 5px;
}
.horizofear {
  width: 20%;
  height: 1px;
  background: #49494a;
  margin-top: 40px;
}
.basic {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 10px;
  line-height: 5px;
  text-align: center;
}
.money {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  color: #ffffff;
  text-align: left;
}
.buynow {
  background: #16977f;
  border-radius: 14px;
  border: 0;
  padding: 10px 35px;
  margin-left: 20px;
  line-height: 15px;
  color: #ffffff;
  font-size: 13px;
}

.Nav {
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
}
img ~ img {
  position: fixed;
  top: 0px;
  height: 50px;
}
.Nav__logo {
  margin-top: 12px;
  margin-right: 30px;
}
.Nav__black {
  position: fixed;
  z-index: 2;
  height: 60px;
  width: 100%;
  top: 0;
  transform: translate(0, 0);
  background-color: #ffffff;
}
.Nav__black .navbar-nav {
  margin-left: auto;
  /* margin-right: auto; */
}
.carousel-indicators {
  display: none;
}
/* .login {
  background: linear-gradient(
    93.1deg,
    #9a8dfa 0.63%,
    rgba(216, 114, 123, 0.57) 96.1%
  );
  border-radius: 12px;
  height: 35px;
  text-align: center;
  border: 0;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 10px;
  padding-right: 20px;
} */

.paratracar {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #49494a;
}
.section {
  padding: 40px 0;
  overflow: hidden;
}
.parafeature {
  font-size: 13px;
  color: white;
}
.rowsed {
  margin-top: 15%;
}
.corerow {
  margin-left: 100px;
  margin-right: 100px;
}
.veh {
  font-size: 14px;
}
.footerreach {
  padding-left: 20px;
}
.play {
  margin-top: -10px;
}
.hview {
  color: #ffffff;
}
.reachus {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  float: left;
  margin-left: 15px;
}
.navigate {
  color: #ffffff;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
.rowfooter {
  margin-left: 15px;
}
.paystore {
  height: 35px;
  width: 50px;
  margin-top: 3px;
}
.paystoe {
  margin-left: 10px;
  margin-top: 5px;
}
.face i {
  color: #ffffff;
  font-size: 20px;
}
.drippic {
  position: absolute;
  /* top: -350px; */
  z-index: 999999999;
  height: 700px;
  width: 500px;
}
.aboutdrip,
.aboutdrip2,
.aboutdrip3 {
  position: absolute;
  z-index: 9999999999999;
}
.aboutdrip3 {
  top: 20%;
  left: -10%;
  z-index: 1;
}
.aboutdrip2 {
  right: 0;
  top: -20%;
  z-index: 1;
  width: 30%;
}
.servicedrip2 {
  left: -60%;
  width: 80%;
  top: -10%;
  position: absolute;
  z-index: 9999999999999;
}
.servicepricedrip2 {
  right: -30%;
  width: 100%;
  top: -50%;
  position: absolute;
  z-index: 9999999999999;
}
.diverer {
  height: 400px;
  float: inline-end;
  margin-left: 60%;
}
.diver {
  /* top: 20px; */
  height: 80px;
  float: inline-end;
  margin-left: 20%;
}
.aboutsection {
  z-index: 1;
  overflow-y: hidden;
  height: 100vh;
  /* top: 600px; */
}
.aboutdrip {
  left: 40%;
  z-index: 1;
}
.seephonecall {
  margin-top: 40px;
  margin-left: 30px;
  object-fit: cover;
}
.imgthinker {
  position: relative;
  z-index: 0;
  height: 400px;
  object-fit: cover;
}
.coward {
  top: 0px;
  z-index: 0;
  position: relative;
}
.features {
  margin-top: -60px;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #665bbf;
}
.carousel-control-prev-icon {
  color: #665bbf;
}
.carousel-control-next-icon {
  color: rgb(114, 104, 196);
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
  display: none;
}

@media (max-width: 768px) {
  .slick-dots li button {
    display: block;
  }
  .rowsed {
    margin-top: 30%;
  }
  .homeimg {
    padding-top: 40px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background: rgba(255, 255, 255, 0.93);
    opacity: 1;

    z-index: 5;
  }
  #hero:before {
    content: "";
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 20vh;
  }

  .seephonecall {
    margin-left: 0px;
    object-fit: cover;
  }
  .downcarsou {
    display: none;
  }
  .upercours {
    margin-top: 10%;
  }
  .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 20px;
  }
  .parafeature {
    font-size: 16px;
    color: white;
    text-align: center;
  }
  .footerulsec {
    margin-left: 60%;
    margin-top: -40%;
  }
  .footerulfirst {
    margin-left: -60%;
  }
  .footerreach {
    margin-left: -10px;
  }
  .conta {
    margin-left: 10px;
  }
  .socialale .face {
    font-size: 5px;
    display: inline-block;
    margin-left: 30px;
  }
  .traku {
    display: none;
  }
  .rowfooter {
    display: none;
  }
  .footerimg {
    display: none;
  }
  .reachus {
    font-size: 13px;
  }
  .ourapp {
    color: #ffffff;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
  }
  .fear {
    margin-top: -5%;
    height: 100%;
    padding-bottom: 2rem;
  }
  .login {
    margin-left: 2%;
  }
  .diver {
    top: 20px;
    margin-left: -4px;
  }
  .buynow {
    background: linear-gradient(180deg, #b68ad4 0%, #d57482 100%);
    border-radius: 14px;
    border: 0;
    padding: 10px 15px;
    margin-left: 0px;
    color: #ffffff;
    font-size: 12px;
  }
  .veh {
    font-size: 10px;
  }
  .devise {
    font-size: 10px;
  }
}
.navbar-expand-lg .navbar-collapse {
  justify-content: center;
  margin-left: -5%;
}
.dropdown-menu {
  position: absolute;
  top: 0;
  right: 100px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.table td,
.table th {
  padding: 8px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.cursorPoint {
  cursor: pointer;
}
.blueColor {
  color: blue;
}
.mat {
  margin-top: -25px;
  margin-bottom: 10px;
}
.redColor {
  color: red;
}

.totaprofit {
  background-color: #9ecfb4;
  color: #363535;
  font-family: sans-serif;
  font-style: normal;
  text-align: left;
  line-height: 5px;
  font-weight: 600;
  font-size: 12px;
}
.fistta table {
  width: 40%;
  margin-left: 100px;
}
.fistta td {
  border: none;
  width: 80%;
}
.footer {
  background-color: #eff1f5;
  border-top: 1.5px solid rgba(6, 22, 39, 0.9);
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 11px;
  padding-right: 0;
  list-style: none;
  justify-content: center;
  padding: 7px;
  bottom: 0;
  position: fixed;
  width: 100%;
}
.btnbookrermore {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  color: #16977f;
  margin-top: 10px;
  border: 2px solid #16977f;
  background: transparent;
  /* line-height: px; */
  padding: 5px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 800;
}

.btnbookrer {
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;

  border: 2px solid #16977f;
  background: transparent;
  /* line-height: px; */
  padding: 5px 5px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 800;
}
.buttoncenter {
  margin: 8px;
  position: absolute;
  text-align: center;
  left: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btnbookr {
  color: #16977f;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border: 2px solid #16977f;
  background: transparent;
  /* line-height: px; */
  padding: 5px 40px;

  margin-left: 20px;
  cursor: pointer;
  border-radius: 3px;
}
.Madl {
  border-radius: 12px;
  background: #eff1f5;
  border-radius: 10px;
  width: 45%;
}
.Datepicker__month-container {
  float: left;
  margin-right: 100%;
}
.confirmTitle.modal-body {
  color: #000000;
  margin-top: 73px;
}

.modal-footer {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: left;
  align-items: left;
  -ms-flex-pack: left;
  justify-content: space-between;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
@media (max-width: 767px) {
  .caerdlog {
    top: 50px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    /* height: 60%; */
    overflow-x: hidden;
    padding: 30px;
    /* background: #ffffff;
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08); */
    border-radius: 20px;
  }
  .tslog {
    font-size: 34px;
    padding-top: 10px;
    line-height: 44px;
    margin-left: 25%;
  }
  .formModaltes {
    -webkit-appearance: none;
    appearance: none;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    color: inherit;
    font-weight: 500;
    /* inline-size: 50%; */
    margin: 0;
    padding: 12px;
    text-overflow: ellipsis;
  }
  .lebelenter {
    margin-left: 30px;
    display: block;
    font-weight: 600;
    transition: color 0.33s ease;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    padding-top: 5px;
    width: 50%;
    font-size: 14px;
    color: #263a43;
  }

  .toggle {
    text-align: right !important;
  }
  .toggle i {
    color: #081829;
    font-size: 25px;
    float: right;
  }
  .tooglebutton {
    position: fixed;
    top: 2px;
    right: 2rem;
    z-index: 999999;
  }
  .header-items {
    max-width: 50%;
  }
  .logo {
    position: absolute;
    width: 10px;
    height: 170px;
    left: 0px;
    font-size: 10px;
    top: -45px;
  }
  .Madl {
    border-radius: 12px;
    background: #eff1f5;
    border-radius: 10px;
    width: 90%;
  }
  .carddetails {
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    color: #fff;
    margin-right: 8px;
    border: 1.5px solid #dfe0eb;
  }
  .cardtable {
    background: #ffffff;
    padding: 20px;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    margin-bottom: 80px;
  }
  .searchadd {
    display: flex;
    padding-right: 68px;
    justify-content: end;
  }
  .detailedcon {
    margin-left: 30px;
    margin-top: -15px;
    display: block;
    padding-bottom: 20px;
  }
  .cardtablecapital {
    padding-left: 0px;
    padding-right: 0px;
  }
  .searchadd {
    display: flex;
    /* padding-left: 10px; */
    /* justify-content: end; */
  }
  .searchaddRoun {
    display: block;
    padding-left: 10px;
  }
  .membaddd {
    display: block;
    justify-content: left;
  }
  .membaddy {
    display: block;
    justify-content: left;
  }
  .caerd {
    background: #ffffff;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 11%);
    border-radius: 10px;
    margin-left: 2px;
    margin-bottom: 100px;
  }
  .loginsearchfa {
    height: 40px;
    width: 9rem;
    font-size: 14px;
    color: #323232;
    margin: 10px;
    padding: 20px 15px;
    line-height: 20px;
    border: 1px solid rgb(204, 194, 194);
    border: 0;
    background: #f1f8ff;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 10px;
    box-shadow: 0 0 5px #e5e9ec;
    border: 1px solid #d7dbe2;
  }
  .loginsearchfaro {
    height: 30px;
    width: 9rem;
    font-size: 14px;
    color: #323232;
    margin-left: 12px;
    padding: 15px 15px;
    line-height: 20px;
    border: 1px solid rgb(204, 194, 194);
    border: 0;
    background: #f1f8ff;
    border-radius: 10px;
    box-shadow: 0 0 5px #e5e9ec;
    border: 1px solid #d7dbe2;
  }
  .btnaddblogadds {
    font-size: 16px;
    margin-left: 10px;
    padding-top: 3px;
    width: 28px;
    height: 28px;
    color: #ffff;
    text-align: center;
    background: #16977f;
    border-radius: 0px;
  }
  .searchloan {
    display: flex;
    margin-left: 100px;
  }
  .rowuno {
    margin-top: -20px;
    display: block;
  }
  .dupa {
    margin-left: 0px;
  }
  .report {
    display: block;
  }
  .buttoncenter {
    margin: 8px;
    position: absolute;
    text-align: center;
    left: 30%;
    transform: translateY(-50%);
  }
  .activeuserr {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    color: #0c3546;
    border-radius: 8px;
    background: rgba(228, 226, 226, 0.13);
    border: 2px solid rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
  .fistta table {
    width: 100%;
    margin-left: 10px;
  }

  .totaldetails {
    display: flex;
    background-color: #e7e7eb;
    padding: 5px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .unityli {
    margin-left: 12px;
    color: #000000;
    font-size: 13px;
    font-weight: 800;
  }

  .conteinputi {
    font-style: normal;
    margin-left: 20px;
    font-weight: 550;
    font-size: 14px;
    line-height: 46px;
    text-decoration: underline;
    color: #3b3939;
  }
  .contrilink {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    text-align: left;
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    margin-left: 5px;
    padding-bottom: 10px;
    color: #4a4a4b;
    cursor: pointer;
  }
  .log {
    display: block;
  }

  .addinfo {
    margin-left: auto;
    float: right;
  }

  /* .table td,
  .table th {
    padding: 3px;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
  } */
  .contcount {
    margin-left: 8px;
    margin-top: 10px;
    display: block;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    /* margin: 50px; */
    width: 100%;
    border-top: 1px solid #e1d8d8;
    border-bottom: 1px solid #e1d8d8;
    border-left: 1px solid #e1d8d8;
    border-right: 1px solid #e1d8d8;
  }

  .modal-body {
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }
  .parainputsapp {
    display: block;
    height: 40px;
    margin-left: 10px;
    margin-bottom: 30px;
    padding: 8px 10px;
    font-size: 14px;
    color: #555;
    background-color: rgb(220, 225, 233);
    background-image: none;
    border-radius: 5px;
  }
  .myappr {
    display: block;
  }
  .viewdetcli {
    color: #ffffff;
    font-size: 14px;
    font-family: sans-serif;
    margin-top: 20px;
    padding: 10px 20px;
    background: #16977f;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
}
.address{
 color:#000000;
 font-weight: 800;
 line-height: 20px;
 
}
.vopogo{
  font-weight: 500;
  line-height: 20px;
}
.viop{
  color:#010101;
  font-weight:500;
  font-size:16px;

 }
 .switch {
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s;
}

.switch.on {
  background: #16977f;
}

.switch .toggle {
  width: 23px;
  height: 23px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
}

.switch.on .toggle {
  left: 26px;
}
@media (max-width: 480px) {
  .lebelenterlog {
    display: block;
    font-weight: 600;
    transition: color 0.33s ease;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    padding-top: 5px;
    width: 100%;
    font-size: 14px;
    color: #263a43;
  }
 
  .formModaltes {
    appearance: none;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    color: inherit;
    font-weight: 500;
    width: 80%;
    /* inline-size: 50%; */
    margin: 0;
    padding: 12px;
    text-overflow: ellipsis;
  }
  .btnapplsign {
    appearance: none;
    background-color: #16977f;
    border: none;
    border-radius: 4px;
    color: inherit;
    font-weight: 500;
    font-size: 18px;
    inline-size: 80%;
    margin: 0;
    padding: 10px;
    text-overflow: ellipsis;
    color: #ffffff;
    font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
    cursor: pointer;
  }
  .linebreak {
    color: #5c5e62;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-right: 40px;
    white-space: nowrap;
  }

  .need-help.tds-text--center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-right: 70px;
    text-align: center !important;
    line-height: 25px;
    color: #121213;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  .need-help.tds-text--center:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-right: 70px;
    text-align: center !important;
    line-height: 25px;
    color: #121213;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  .viewdetcli {
    color: #ffffff;
    font-size: 14px;
    font-family: sans-serif;
    margin-top: 20px;
    padding: 10px 20px;
    background: #16977f;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
}

.colorspino {
  background-color: #16977f;
  /* margin-top: 150px; */
  height: 50px;
  width: 50px;
}
.loading-spinner {
  width: 70px;
  height: 70px;
  border: 10px solid #383636;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



